@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}


.form-control {
  width: 60%;
}

.form-control:focus {
  box-shadow: none;
}

.btn {
  margin-top: 10px;
}

.btn-submit {
  background-color: #2e7d32;
  color: white;
}

#footer {
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 40px;
  background: grey;         /* Footer height */
}

.tool-bar {
  margin: 0px 10px 0px 10px;
}

.fulltext-search-panel {
  margin: 6px;
  width: calc(100% - 12px);
}

.highlight {
  background-color: '#FFD800',
}

.table-wrapper {
  overflow-x: scroll;
  width: calc(100% - 12px);
  overflow-y: auto;
  height: calc(100vh - 250px);
}

.table-wrapper th {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  background-color: #CCC;
  z-index: 100;
}

.head-column {
  min-width: 150px;
}

.sort-button-column {
  min-width: 250px;
}

.lokace-column {
  min-width: 300px;
}

.akce-column {
  min-width: 200px;
}

.editable-column-input {
  min-width: 200px !important;
}

.form-row-wrapper {
  display: flex;
  margin: 0 16px 0 16px;
  font-weight: bold;
  align-items: flex-end;
}

.clearFiltersFAB {
  position: fixed;
  right: 5vh;
  bottom: 5vh;
}

